import { AUTH_CONFIG } from '../auth/config';
import { Environment } from '../configuration/Environment';
import { AssistantDto } from '../models/AssistantDto';
import { InitiateAssistantThreadResultDto } from '../models/InitiateAssistantThreadResultDto';
import { OrganizationDto } from '../models/OrganizationDto';
import { CreateUserDto, CreateUserResultDto, UserDto } from '../models/UserDto';

export const errorMessage = 'There has been an error communicating with the chatbot. Please try again or contact support.';

const EndpointRoutes = {
    initiateThread: () => `${Environment.getBsrOpenAiApiBasePath()}/api/v1/Assistant/initiate-thread`,
    initiateAssistantThread: (assistantId: string) => `${Environment.getBsrOpenAiApiBasePath()}/api/v1/Assistant/initiate-thread?assistantId=${assistantId}`,
    sendMessage: () => `${Environment.getBsrOpenAiApiBasePath()}/api/v1/Assistant/send-user-message`,
    getAssistant: (assistantId: string) => `${Environment.getBsrOpenAiApiBasePath()}/api/v1/Assistant/${assistantId}`,
    getAssistants: () => `${Environment.getBsrOpenAiApiBasePath()}/api/v1/Assistant/list`,
    getOrganizations: () => `${Environment.getBsrOpenAiApiBasePath()}/api/v1/Organizations/list`,
    getUsers: () => `${Environment.getBsrOpenAiApiBasePath()}/api/v1/Users/list`,
    getCurrentUser: () => `${Environment.getBsrOpenAiApiBasePath()}/api/v1/Users/current`,
    createAssistant: () => `${Environment.getBsrOpenAiApiBasePath()}/api/v1/Assistant/create`,
    createOrganization: () => `${Environment.getBsrOpenAiApiBasePath()}/api/v1/Organizations/create`,
    createUser: () => `${Environment.getBsrOpenAiApiBasePath()}/api/v1/Users/create`,
    updateAssistant: () => `${Environment.getBsrOpenAiApiBasePath()}/api/v1/Assistant/update`,
    updateOrganization: () => `${Environment.getBsrOpenAiApiBasePath()}/api/v1/Organizations/update`,
    updateUser: () => `${Environment.getBsrOpenAiApiBasePath()}/api/v1/Users/update`,
} as const;

type BackendActionName = keyof typeof EndpointRoutes;
type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

const EndpointMethods: Readonly<{ [K in BackendActionName]: HttpMethod; }> = {
    initiateThread: 'POST',
    initiateAssistantThread: 'POST',
    sendMessage: 'POST',
    getAssistant: 'GET',
    getAssistants: 'GET',
    getOrganizations: 'GET',
    getUsers: 'GET',
    getCurrentUser: 'GET',
    createAssistant: 'POST',
    createOrganization: 'POST',
    createUser: 'POST',
    updateAssistant: 'POST',
    updateOrganization: 'POST',
    updateUser: 'POST',
} as const;

type EndpointRouteParameters<A extends BackendActionName> = Parameters<(typeof EndpointRoutes)[A]>;

const performBackendAction = async <A extends BackendActionName, TResult>(actionParameters: {
    actionName: A, 
    urlParams: EndpointRouteParameters<A>,
    fromResponse: (jsonObj: Response) => Promise<TResult>,
}) => {
    const method = EndpointMethods[actionParameters.actionName];
    const requestOptions: RequestInit = {
        method: method,
        headers: await getHeaders(),
        redirect: 'follow',
    };

    const urlConstructor = EndpointRoutes[actionParameters.actionName] as any;
    const urlParams = actionParameters.urlParams as any;
    const url: string = urlConstructor(...urlParams);

    const response = await fetch(url, requestOptions);
    if (!response.ok) {
        return null;
    }

    const result = actionParameters.fromResponse(response);
    return result
}

const performBackendActionWithJsonBody = async <A extends BackendActionName, TResult>(actionParameters: {
    actionName: A, 
    urlParams: EndpointRouteParameters<A>,
    requestBody: unknown,
    fromResponse: (jsonObj: Response) => Promise<TResult>,
}) => {
    const method = EndpointMethods[actionParameters.actionName];
    const requestOptions: RequestInit = {
        method: method,
        headers: await getHeaders(),
        redirect: 'follow',
        body: JSON.stringify(actionParameters.requestBody),
    };

    const urlConstructor = EndpointRoutes[actionParameters.actionName] as any;
    const urlParams = actionParameters.urlParams as any;
    const url: string = urlConstructor(...urlParams);

    const response = await fetch(url, requestOptions);
    if (!response.ok) {
        return null;
    }

    const result = actionParameters.fromResponse(response);
    return result;
}

const performBackendActionWithBody = async <A extends BackendActionName, TResult>(actionParameters: {
    actionName: A,
    urlParams: EndpointRouteParameters<A>,
    requestBody: FormData,
    fromResponse: (jsonObj: Response) => Promise<TResult>,
}) => {
    const method = EndpointMethods[actionParameters.actionName];
    const requestOptions: RequestInit = {
        method: method,
        headers: await getHeadersForm(),
        redirect: 'follow',
        body: actionParameters.requestBody,
    };

    const urlConstructor = EndpointRoutes[actionParameters.actionName] as any;
    const urlParams = actionParameters.urlParams as any;
    const url: string = urlConstructor(...urlParams);

    const response = await fetch(url, requestOptions);
    if (!response.ok) {
       const jsonResponse = await response.json();
       return jsonResponse.message;
    }

    const result = actionParameters.fromResponse(response);
    return result;
}

const getHeaders = async () => {
    const oidc_data = localStorage.getItem(`oidc.user:${AUTH_CONFIG.authority}:${AUTH_CONFIG.client_id}`);
    const headers = new Headers();
    if (oidc_data === null) {
        return headers;
    } 

    const parseResult: unknown = JSON.parse(oidc_data);
    if (!(typeof parseResult === 'object' && parseResult !== null && 'token_type' in parseResult && 'access_token' in parseResult)) {
        return headers;
    }

    const authorizationType = parseResult.token_type;
    const myToken = parseResult.access_token;
    
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${authorizationType} ${myToken}`);

    return headers;
}

const getHeadersForm = async () => {
    const oidc_data = localStorage.getItem(`oidc.user:${AUTH_CONFIG.authority}:${AUTH_CONFIG.client_id}`);
    const headers = new Headers();
    if (oidc_data === null) {
        return headers;
    }

    const parseResult: unknown = JSON.parse(oidc_data);
    if (!(typeof parseResult === 'object' && parseResult !== null && 'token_type' in parseResult && 'access_token' in parseResult)) {
        return headers;
    }

    const authorizationType = parseResult.token_type;
    const myToken = parseResult.access_token;

    headers.append('Authorization', `${authorizationType} ${myToken}`);

    return headers;
}

export const Requester = {
    initiateThread: async (assistantId: string | null): Promise<InitiateAssistantThreadResultDto | null> => {
        const assistantIdSearchParam = assistantId;
        const fromResponse = async (response: Response) => {
            const json = await response.json();
            const result = InitiateAssistantThreadResultDto.fromJsonObject(json);
            return result;
        }

        if (assistantIdSearchParam === null) {
            return await performBackendAction({
                actionName: 'initiateThread',
                urlParams: [],
                fromResponse,
            });
        }
        else {
            return await performBackendAction({
                actionName: 'initiateAssistantThread',
                urlParams: [assistantIdSearchParam],
                fromResponse,
            });
        }
    },
    
    sendMessage: async (threadId: string, message: string, file: any, assistantId: string): Promise<string> => {
        const fromResponse = async (response: Response) => await response.text();
        const formData= new FormData();
        formData.append('assistantId', assistantId);
        formData.append('threadId', threadId);
        formData.append('message', message);
        formData.append('file', file);

        const result = await performBackendActionWithBody({
            actionName: 'sendMessage',
            urlParams: [],
            requestBody: formData,
            fromResponse,
        });

        if (result === null) {
            return errorMessage;
        }

        return result;
    },
    
    getAssistant: async (assistantId: string): Promise<AssistantDto | null> =>
    {
        const fromResponse = async (response: Response) => {
            const json = await response.json();
            const result = json;
            return result;
        }

        return await performBackendAction({
            actionName: 'getAssistant',
            urlParams: [assistantId],
            fromResponse,
        });
    },

    getAssistants: async (): Promise<AssistantDto[] | null> => {
        const fromResponse = async (response: Response) => {
            const json = await response.json();
            const data: AssistantDto[] = []; 
            for (const jsonItem of json) {
                data.push(jsonItem);
            }
        
            return data;
        }

        return await performBackendAction({
            actionName: 'getAssistants',
            urlParams: [],
            fromResponse,
        });
    },

    getOrganizations: async (): Promise<OrganizationDto[] | null> => {
        const fromResponse = async (response: Response) => {
            const json = await response.json();
            return json as OrganizationDto[];
        }

        return await performBackendAction({
            actionName: 'getOrganizations',
            urlParams: [],
            fromResponse,
        });
    },

    getUsers: async (): Promise<UserDto[] | null> => {
        const fromResponse = async (response: Response) => {
            const json = await response.json();
            return json as UserDto[];
        }

        return await performBackendAction({
            actionName: 'getUsers',
            urlParams: [],
            fromResponse,
        });
    },

    getCurrentUser: async (): Promise<UserDto | null> => {
        const fromResponse = async (response: Response) => {
            const json = await response.json();
            return json as UserDto;
        }

        return await performBackendAction({
            actionName: 'getCurrentUser',
            urlParams: [],
            fromResponse,
        });
    },

    createUser: async (user: CreateUserDto): Promise<CreateUserResultDto | null> => {
        const fromResponse = async (response: Response) => {
            const json = await response.json();
            return json as CreateUserResultDto;
        };

        return await performBackendActionWithJsonBody({
            actionName: 'createUser',
            urlParams: [],
            requestBody: user,
            fromResponse,
        });
    },

    updateUser: async (user: UserDto): Promise<'ok' | null> => {
        const fromResponse = async (): Promise<'ok'> => Promise.resolve('ok');
        return await performBackendActionWithJsonBody({
            actionName: 'updateUser',
            urlParams: [],
            requestBody: user,
            fromResponse,
        });
    },

    createAssistant: async (assistant: AssistantDto): Promise<string | null> => {
        const fromResponse = async (response : Response): Promise<string> => {
            const json = await response.json();
            return json.assistantId;
        };

        return await performBackendActionWithJsonBody({
            actionName: 'createAssistant',
            urlParams: [],
            requestBody: assistant,
            fromResponse,
        });
    },

    updateAssistant: async (assistant: AssistantDto): Promise<'ok' | null> => {
        const fromResponse = async (): Promise<'ok'> => Promise.resolve('ok');
        return await performBackendActionWithJsonBody({
            actionName: 'updateAssistant',
            urlParams: [],
            requestBody: assistant,
            fromResponse,
        });
    },

    createOrganization: async (organization: OrganizationDto): Promise<'ok' | null> => {
        const fromResponse = async (): Promise<'ok'> => Promise.resolve('ok');
        return await performBackendActionWithJsonBody({
            actionName: 'createOrganization',
            urlParams: [],
            requestBody: organization,
            fromResponse,
        });
    },

    updateOrganization: async (organization: OrganizationDto): Promise<'ok' | null> => {
        const fromResponse = async (): Promise<'ok'> => Promise.resolve('ok');
        return await performBackendActionWithJsonBody({
            actionName: 'updateOrganization',
            urlParams: [],
            requestBody: organization,
            fromResponse,
        });
    },
};