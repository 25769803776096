// src/components/Chat.tsx
import React from 'react';
import {Grid, TextField, LinearProgress, Button, CircularProgress, IconButton, Tooltip} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import {AttachFile} from '@mui/icons-material';

interface InputProps {
    isWaiting: boolean;
    input: string;
    file: any;
    setInput: React.Dispatch<React.SetStateAction<string>>;
    setFile: React.Dispatch<React.SetStateAction<any>>;
    handleKeyPress: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    handleSendMessage: () => Promise<void>;
    imageUploadEnabled: boolean;
    documentUploadEnabled: boolean;
  }

// eslint-disable-next-line max-len
const MessageInput: React.FC<InputProps> = ({isWaiting, input,file, setInput, setFile, handleKeyPress, handleSendMessage, imageUploadEnabled, documentUploadEnabled}) => {
    const imageTypes: Array<string> = ['.png', '.jpg', '.jpeg', '.gif'];
    const docTypes: Array<string> = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/pdf', 'text/plain'];
    const toolTip: string = file? file.name.toString() :
        (imageUploadEnabled && documentUploadEnabled)? 'Upload een document of afbeelding' :
    imageUploadEnabled? 'Upload een afbeelding' : 'Upload een document';
     return (
    <Grid 
      container 
      direction="row" 
      paddingBottom={5} 
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{ 'width': 'calc(100% + 16px)', marginLeft: '-16px' }}
    >
        <Grid item sm={11} xs={10}>
        <TextField
          label="Type your message"
          variant="outlined"
          disabled={isWaiting}
          autoComplete="off"
          fullWidth
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyPress}
          sx={{borderRadius: '0 0 4px 4px'}}
          InputLabelProps={!input && (imageUploadEnabled || documentUploadEnabled)? { shrink: false, style: { paddingLeft: '2.5rem' } } : {}}
          InputProps={imageUploadEnabled || documentUploadEnabled? {
              startAdornment: (
                  <Tooltip title={toolTip} placement="top">
                  <IconButton component="label">
                      <AttachFile sx={!file? {} : {'fontSize' : 25, 'color': 'green'}}
                      />
                      <input
                        style={{'display': 'none', 'width' : '0px'}}
                        type="file"
                        hidden
                        name="[file]"
                        onChange={(e) => {
                            const file = e.target.files![0];
                            if((imageUploadEnabled && imageTypes.includes('.' + file!.name.split('.').pop())) ||
                                (documentUploadEnabled && docTypes.includes(file!.type)))
                            {
                                setFile(e.target.files![0])
                            }else{
                                alert('Een ongeldig bestandstype geupload. Probeer een ander bestand');
                            }
                        }
                      }
                        onClick={(e) =>  { const element = e.target as HTMLInputElement; element.value = ''; setFile(null); }}
                        accept = { imageUploadEnabled && documentUploadEnabled? imageTypes.concat(docTypes).toString() :
                            imageUploadEnabled? imageTypes.toString() : docTypes.toString() }
                            />
                  </IconButton>
                  </Tooltip>
              ),
          } : {}}
        />
          {isWaiting && <LinearProgress color="inherit" />}
      </Grid>
      <Grid item sm={1} xs={2}>
        <Button 
          variant="contained" 
          fullWidth color="primary" 
          onClick={handleSendMessage} 
          disabled={isWaiting} 
          sx={{ height: 'calc(100% - 1px)' }}
        >
          {isWaiting && <CircularProgress color="inherit" />}
          {!isWaiting && <SendIcon fontSize="large" />}
        </Button> 
      </Grid>
    </Grid>
  );
};

export default MessageInput;
