import { useEffect, useState } from 'react';
import { Requester } from '../helpers/Requester';
import {
    MenuItem,
    Select,
    TextField,
    Button,
    Container,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Switch,
} from '@mui/material';
import { OrganizationDto } from '../models/OrganizationDto';
import { useNavigate } from 'react-router-dom';
import { ChatbotRoutes } from '../helpers/Routes';
import { AssistantDto } from '../models/AssistantDto';

type AssistantPageParams = 
    | {
        assistantId: string,
        isCreation: false,
    }
    | {
        assistantId: null,
        isCreation: true,
    }

export const AssistantPage = (params: AssistantPageParams) => {
    const [assistant, setAssistant] = useState<AssistantDto | null>(null);
    const [isCreation] = useState(params.isCreation);
    const [organizations, setOrganizations] = useState<OrganizationDto[]>([]);
    const [organizationsMenuItems, setOrganizationsMenuItems] = useState<JSX.Element[]>([]);
    const [notFound, setNotFound] = useState(false);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    const [assistantId] = useState(params.assistantId === null ? '' : params.assistantId);
    const [assistantName, setAssistantName] = useState('');
    const [assistantFriendlyName, setAssistantFriendlyName] = useState('');
    const [assistantOrganization, setAssistantOrganization] = useState('');
    const [assistantOpenAiId, setAssistantOpenAiId] = useState('');
    const [assistantWelcomeMessage, setAssistantWelcomeMessage] = useState('');
    const [assistantImageUploadEnabled, setAssistantImageUploadEnabled] = useState(false);
    const [assistantDocumentUploadEnabled, setAssistantDocumentUploadEnabled] = useState(false);

    const submit = async () => {
        const newAssistant: AssistantDto = {
            id: assistantId,
            name: assistantName,
            friendlyName: assistantFriendlyName,
            organizationId: assistantOrganization,
            openAiId: assistantOpenAiId,
            welcomeMessage: assistantWelcomeMessage,
            imageUploadEnabled: assistantImageUploadEnabled,
            documentUploadEnabled: assistantDocumentUploadEnabled,
        };

        const action = isCreation 
            ? async (x: AssistantDto) => {
                const id = await Requester.createAssistant(x);
                if (id === null) {
                    return null;
                }

                return id;
            }
            : async (x: AssistantDto) => {
                const id = await Requester.updateAssistant(x);
                if (id === null) {
                    return null;
                }

                return x.id;
            };

        const id = await action(newAssistant);
        if (id === null) {
            setIsError(true);
            return;
        }
        
        newAssistant.id = id;
        setAssistant(newAssistant);

        if (isCreation) {
            navigate(ChatbotRoutes.assistants, { replace: true });
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const fetchedOrganizations = await Requester.getOrganizations();
            if (fetchedOrganizations === null) {
                setIsError(true);
                return;
            }

            const firstOrganization = fetchedOrganizations[0];
            if (firstOrganization === undefined) {
                setIsError(true);
                return;
            }

            setOrganizations(fetchedOrganizations);
            if (params.isCreation) {
                setAssistant({
                    id: '',
                    friendlyName: '',
                    name: '',
                    openAiId: '',
                    organizationId: firstOrganization.id,
                    welcomeMessage: '',
                    imageUploadEnabled: false,
                    documentUploadEnabled: false,
                });
            }
            else {
                const fetchedAssistants = await Requester.getAssistants();
                if (fetchedAssistants === null) {
                    setIsError(true);
                    return;
                }
    
                const fetchedAssistant = fetchedAssistants.find(x => x.id === params.assistantId);
                if (fetchedAssistant === undefined) {
                    setNotFound(true);
                    return;
                }
    
                setAssistant(fetchedAssistant);
            }        
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (assistant === null) {
            return;
        }

        setAssistantName(assistant.name);
        setAssistantFriendlyName(assistant.friendlyName);
        setAssistantOrganization(assistant.organizationId);
        setAssistantWelcomeMessage(assistant.welcomeMessage);
        setAssistantOpenAiId(assistant.openAiId === null ? '' : assistant.openAiId);
        setAssistantImageUploadEnabled(assistant.imageUploadEnabled);
        setAssistantDocumentUploadEnabled(assistant.documentUploadEnabled);
    }, [assistant]);

    useEffect(() => {
        const organizationsItems: JSX.Element[] = [];
        for (const organization of organizations) {
            organizationsItems.push(
                <MenuItem value={organization.id}>{organization.name}</MenuItem>,
            );
        }

        setOrganizationsMenuItems(organizationsItems);
    }, [organizations]);

    if (assistant === null) {
        return <p>Loading...</p>;
    }

    if (isError) {
        return <p>Error</p>;
    }

    if (notFound) {
        return <p>Not found</p>;
    }

    return (
        <Container>
            <h1>{isCreation ? 'Create assistant' : `Update assistant ${assistant.friendlyName}`}</h1>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>
                            <TextField 
                              value={assistantName} 
                              required={true} 
                              onChange={e => setAssistantName(e.target.value)}
                              style={{width: '100%'}} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Friendly name</TableCell>
                        <TableCell>
                            <TextField 
                              value={assistantFriendlyName} 
                              required={true} 
                              onChange={e => setAssistantFriendlyName(e.target.value)}
                              style={{width: '100%'}} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>OpenAI Id</TableCell>
                        <TableCell>
                            <TextField 
                              value={assistantOpenAiId} 
                              required={true} 
                              onChange={e => setAssistantOpenAiId(e.target.value)}
                              style={{width: '100%'}} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Organization</TableCell>
                        <TableCell>
                            <Select 
                              value={assistantOrganization} 
                              required={true} 
                              onChange={e => setAssistantOrganization(e.target.value)}
                              style={{width: '100%'}}
                            >
                                {organizationsMenuItems}
                            </Select>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Welcome message</TableCell>
                        <TableCell>
                            <TextField 
                              value={assistantWelcomeMessage} 
                              required={true} 
                              onChange={e => setAssistantWelcomeMessage(e.target.value)}
                              style={{width: '100%'}} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Image upload</TableCell>
                        <TableCell>
                            <Switch
                              checked={assistantImageUploadEnabled}
                              onChange={e => setAssistantImageUploadEnabled(e.target.checked)}
                              color="secondary"
                               />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Document upload</TableCell>
                        <TableCell>
                            <Switch
                              checked={assistantDocumentUploadEnabled}
                              onChange={e => setAssistantDocumentUploadEnabled(e.target.checked)}
                              color="secondary"
                               />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Button color="secondary" onClick={submit}>Submit</Button>
        </Container>
    );
};