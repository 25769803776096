// src/components/Chat.tsx
import { useContext } from 'react';
import { Grid, Button, Link, FormControl, InputLabel, Select, MenuItem, useMediaQuery, Box } from '@mui/material';
import { AssistantDto } from '../models/AssistantDto';
import { AuthContext } from '../auth';
import { AdminPanelPopup } from './AdminPanelPopup';
import  ArrowDropDownIcon  from '@mui/icons-material/ArrowDropDown';

interface IHeader {
    assistant: AssistantDto | null,
    assistants: Array<AssistantDto | null>
    isAdmin: boolean
    setAssistant(a: AssistantDto | null): void
}

export const Header = ({
    assistant,
    assistants,
    isAdmin,
    setAssistant,
}: IHeader) => {
    const {logoutFromContext} = useContext(AuthContext);

    const onlySmallScreens = useMediaQuery('(min-width:992px)');

    return (
        <Grid
          container
          direction="column"
          spacing={2}
          paddingBottom={0}
          paddingTop={2}
          paddingLeft={0}
          marginTop={2}
          sx={{
                textAlign: 'center',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderBottom: 0,
                borderRadius: '4px 4px 0 0',
                backgroundColor: '#373736',
          }}
        >
            <Grid
              paddingLeft={0}
              paddingBottom={0}
              alignItems="center"
              justifyContent="center"
              sx={{position: 'relative'}}
            >
                <img
                  src="https://marketresponse.nl/wp-content/uploads/sites/4/2023/09/MR-Product-logos-256x65px_BSR-by-MR-WIT-1.png"
                  alt="BSR Market Response"/>
                <Button
                  sx={{position: 'absolute', right: 16}}
                  variant={'contained'} color="secondary" onClick={logoutFromContext}>Logout</Button>
                {isAdmin && <AdminPanelPopup trigger={
                    <Button
                      sx={{position: 'absolute', right: 150}}
                      variant={'contained'} color="secondary">Admin panel</Button>
                }/>}
            </Grid>

            <Grid
              paddingLeft={0}
              paddingBottom={2}
              marginTop={0}
              alignItems="center"
              justifyContent="center"
            >
                <FormControl variant="standard" sx={{m: 1, minWidth: 150}}>
                    <InputLabel id="select-assistant-label" sx={{
                        color: 'white',
                    }}>Segmentatie</InputLabel>
                    <Select
                      labelId="select-assistant-label"
                      id="select-assistant"
                      value={assistant?.id ?? ''}
                      sx={{
                            backgroundColor: 'white',
                            color: 'black',
                      }}
                      inputProps={{readOnly: assistants.length == 1}}
                      IconComponent={assistants.length == 1? () => null : ArrowDropDownIcon }
                      onChange={(e) => setAssistant(assistants.find(f => f?.id == e.target.value)?? null)}
                    >
                        {assistants.map(assistant => {
                            return (
                                <MenuItem key={assistant?.id} value={assistant?.id}>{assistant?.friendlyName}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid sx={{position: 'relative'}}>
                <Grid
                  sx={{
                        position: 'absolute',
                        right: 16,
                        bottom: '18px',
                        fontSize: 'small',
                    }}>
                    <Link href='mailto:support@marketresponse.nl?subject=BSR Chatbot'
                      sx={{color: 'rgb(95, 95, 94)', '&:hover': {color: 'white !important'}}}>
                        <Box display={onlySmallScreens ? 'inline' : 'none'}>Hulp nodig?
                            Contact </Box> support@marketresponse.nl
                    </Link>
                </Grid>
                <Grid
                  sx={{
                        position: 'absolute',
                        right: 16,
                        bottom: '32px',
                        fontSize: 'small',
                    }}>
                    <Link target='_blank' href='https://web4.marketresponse.nl/q/1234581/index.php'
                      sx={{color: 'rgb(95, 95, 94)', '&:hover': {color: 'white !important'}}}>
                        <Box display={onlySmallScreens ? 'inline' : 'none'}>Feedback? Klik hier</Box>
                    </Link>
                </Grid>
            </Grid>

        </Grid>
    );
};
